import React, { useCallback, useEffect, useState } from "react"
import StyledDetails from "./style/details"
import CTA from "../common/CTA"
import Button from "../common/Button"
import { Link } from "gatsby"
// import { getJobs } from "../../newservices/Api"

const toKebabCase = str => {
  return str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join("-")
}

const Details = ({ data }) => {
  // const [loading, setLoading] = useState(false)
  // const [jobs, setJobs] = useState([])
  // console.log("testimonial", jobs)
  // const fetchJobs = useCallback(async () => {
  //   setLoading(true)

  //   try {
  //     const response = await getJobs()
  //     console.log("res", response)
  //     if (response.status === 200) {
  //       const filteredJobs = response.data.jobCareers.activeJobCareers.filter(
  //         job => job.designationId?.company?.organizationName === "Cresol"
  //       )

  //       setJobs(filteredJobs)
  //     }
  //   } catch (error) {
  //     console.error("Error fetching Testimonial:", error)
  //   } finally {
  //     setLoading(false)
  //   }
  // }, [])

  // useEffect(() => {
  //   fetchJobs()
  // }, [fetchJobs])
  return (
    <StyledDetails>
      <div className="main-container">
        <h2>Openings at Cresol Infoserv</h2>
        <p className="uppercase">
          Positive pleasure-oriented goals are much more powerful motivators
          than negative fear-based ones.
        </p>
        <ul>
          {data.group &&
            data.group.map((d, i) => (
              <li className="info glassmorphism " key={i}>
                <h4>{d.fieldValue}</h4>
                <p>Hyderabad</p>
                {/* <CTA
                  link={`/${toKebabCase(d.fieldValue)}/`}
                  type={i === 1 ? "primary white" : "secondary"}
                  text="View Jobs"
                /> */}
                <Link to={`/${toKebabCase(d.fieldValue)}/`}>
                  <Button name="See Open Positions" />
                </Link>
              </li>
            ))}
        </ul>

        {/* <ul>
          {jobs &&
            jobs.map((d, i) => (
              <li className="info glassmorphism " key={i}>
                <h4>{d.designationId.designationName}</h4>
                <p>{d.jobLocation}</p>
                <CTA
                  link={`/${toKebabCase(d.fieldValue)}/`}
                  type={i === 1 ? "primary white" : "secondary"}
                  text="View Jobs"
                />

                <Button name="See Open Positions" />
              </li>
            ))}
        </ul> */}
      </div>
    </StyledDetails>
  )
}

export default Details
